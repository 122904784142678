export const ServicesStrings = {
  serviceOffering: {
    heading: 'Our core service offering',
    hrRecruiting: {
      heading: 'HR Recruitment',
      desc: 'Finding the perfect employees '+
      'can be time consuming, at Betrworkr we ensure that '+
      'organizations recruit the best talent into vacant positions '+
      'without any rigorous procedures. \n\nBased on the client’s'+
      ' specification, we source individuals with '+
      'the technical experience and social skills required to contribute to '+
      'the team\'s success.',
    },
    staffAugmentation: {
      heading: 'Staff Augmentation',
      desc: 'The constant evolution of technology and business needs leaves '+
      'the demands on personnel highly susceptible to change; our staff '+
      'augmentation model enables organizations to adapt in effective and '+
      'efficient ways. \n \nIt involves expanding a team with committed '+
      'experts on short term or long-term basis without the liabilities '+
      'of contracting employees.\n\n Our augmentation program helps to save '+
      'organizations the hassle of recruitment and training of employees, we '+
      'help you to find the best talent with the necessary skillset. \n\nOur '+
      'team members can be deployed as needed to fill talent gaps within the '+
      'organization temporarily or permanently, they function as part of your '+
      'team, while we pay the assigned team members and take care of their '+
      'taxes and benefits.',
    },
  },
  fourWayProcess: {
    heading: 'Our 4-way Process',
    screening: {
      heading: '1. Screening',
      desc: 'The screening process begins with the review of a candidate\'s '+
      'employment application and resume followed by a telephone interview. '+
      'The review is to ensure applicants who do not meet the basic '+
      'requirements for a position are ruled out. The candidate\'s social '+
      'networking profile is also used as a tool in the screening process.',
    },
    vetting: {
      heading: '2. Vetting',
      desc: 'The vetting process allows us to verify information the job '+
      'candidates have provided during the application process, from '+
      'credentials to professional references. This is to understand their '+
      'work ethic, skills, performance and other factors that can influence '+
      'performance on the job.\n\nAn initial phone conversation ascertains '+
      'the candidate\'s overall communication skills, ability to listen, '+
      'attitude and professionalism. During the call, we are able to '+
      'determine if a candidate has the right education, experience and '+
      'knowledge to do the job.Finally, we get a sense of the candidate\'s '+
      'motivation for applying to the role to make sure they’re expectations '+
      'are in line with the clients. The telephone conversation includes '+
      'questions designed to eliminate candidates who are not eligible for '+
      'consideration.',
    },
    testing: {
      heading: '3. Testing',
      desc: 'The purpose of testing is to help predict how well an individual '+
      'will perform on the job, depending on the position, an in-depth '+
      'assessment may be necessary to ensure the individual has the necessary '+
      'skills and competencies to excel on the job.',
    },
    recruiting: {
      heading: '4. Recruiting',
      desc: 'With our thorough processes, you are rest assured knowing that '+
      'only top notch talent is delivered to our clients to begin their '+
      'interview process.',
    },
  },
};
