import React from 'react';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import FooterContainer from '../../containers/Footer/FooterContainer';
import NotFound from '../../containers/NotFound/NotFound';

const NotFoundPage = () => {
  return (
    <div>
      <ScrollToTop />
      <NotFound />
      <FooterContainer />
    </div>
  );
};

export default NotFoundPage;
