import React, {HTMLAttributes} from 'react';
import './formelements.style.scss';

export interface textinputProp extends HTMLAttributes<HTMLInputElement> {
  labelName: string;
  placeholder?: string;
  name: string;
  inputType: string;
  onChange?: (e: any) => void;
  required?: boolean;
  value?: string;
}

const TextInput = ({
  labelName,
  inputType,
  name,
  placeholder,
  onChange,
  required,
  value,
}: textinputProp) => {
  return (
    <div className="form-block" data-testid="input-component">
      <label htmlFor={name} className="form-label">
        {labelName}
      </label>
      <input
        type={inputType}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        className="form-style"
        required={required}
        name={name}
      />
    </div>
  );
};

export default TextInput;
