import * as React from 'react';
import images from '../images';
import './svgstyles.scss';

const TestingSVG = (props) => (
  <div className='testingsvg-container'>
    <img src={images.Testing01} alt='Testing1' id='testing01' />
    <img src={images.Testing02} alt='Testing2' id='chat-pink' />
    <img src={images.Testing03} alt='Testing3' id='chat-yellow' />
    <img src={images.Testing04} alt='Testing4' id='magnifying-glass' />
    <img src={images.Testing05} alt='Testing5' id='circle-task' />
  </div>
);

export default TestingSVG;
