import React, {useCallback, useEffect, useRef, useState} from 'react';
import {TestimonialsData3} from '../../resource/strings/TestimonialData';
import './testimonialcarousel.style.scss';

const TestimonialCarousel = () => {
  const [pagRotate, setPageRotate] = useState(0);
  const [activePag, setActivePag] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const dotsN = TestimonialsData3.length;
  const lastPage = 5;
  const firstPage = 0;
  const timer: React.MutableRefObject<any | null> = useRef();

  // const prevClick = () => {
  //   setPageRotate(pagRotate + 60);
  //   checkActivePrev(activePag);
  // };
  // const nextClick = () => {
  //   setPageRotate(pagRotate - 60);
  //   checkActiveNext(activePag);
  // };

  const checkActivePrev = useCallback((num: number) => {
    if (num === firstPage) {
      setActivePag(lastPage);
    } else {
      setActivePag(activePag - 1);
    }
  }, [activePag],
  );
  // const checkActiveNext = useCallback(
  //   (num: number) => {
  //     if (num === lastPage) {
  //       setActivePag(firstPage);
  //     } else {
  //       setActivePag(activePag + 1);
  //     }
  //   },
  //   [activePag]
  // );

  const getStyle = (num: number) => {
    if (num === activePag) {
      return 'active';
    } else {
      return '';
    }
  };

  const stopAutoplay = () => {
    setIsPaused(true);
    clearTimeout(timer.current);
  };

  const resumeAutoplay = () => {
    setIsPaused(false);
  };

  const onThumbClick = (num: number) => {
    setActivePag(num);
    if (num === 0) {
      setPageRotate(0);
    } else if (num === 1) {
      setPageRotate(-60);
    } else if (num === 2) {
      setPageRotate(-120);
    } else if (num === 3) {
      setPageRotate(-180);
    } else if (num === 4) {
      setPageRotate(-240);
    } else if (num === 5) {
      setPageRotate(-300);
    }
  };

  useEffect(() => {
    timer.current = setTimeout(() => {
      if (!isPaused) {
        checkActivePrev(activePag);
        setPageRotate(pagRotate + 60);
      }
    }, 5000);
    return () => clearTimeout(timer.current);
  }, [activePag, checkActivePrev, pagRotate, isPaused]);

  return (
    <div className='carousel-container'>
      <div className='circle-carousel'>
        <div
          className='slides'
          onMouseOver={() => stopAutoplay()}
          onMouseLeave={() => resumeAutoplay()}
          onClick={stopAutoplay}
        >
          <div className='rect1'></div>
          <div className='rect2'></div>
          {TestimonialsData3.map((item, index) => (
            <div className={`slide ${getStyle(index)}`} key={`slide-${index}`}>
              <div>
                <div className='feedbackDiv'>
                  <p>{item.feedback}</p>
                </div>
                <div className='detailsDiv'>
                  <h3>{item.clientName}</h3>
                  <p>{item.jobDesc}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div
          className='pagination'
          style={{transform: `translate(-50%, -50%) rotate(${pagRotate}deg)`}}
        >
          {TestimonialsData3.map((item, index) => (
            <div
              className={`item ${getStyle(index)}`}
              key={`pag-${index}`}
              style={{transform: 'rotate(' + (360 / dotsN) * index + 'deg)'}}
              onClick={() => onThumbClick(index)}
            >
              <div className='thumb'>
                <img src={item.imgURL} alt={item.alt} />
              </div>
            </div>
          ))}
        </div>
        <div className='slide2'></div>
      </div>
    </div>
  );
};

export default TestimonialCarousel;
