
import React, {Component} from 'react';
import Slider from 'react-slick';
import {images} from '../../resource';
import {ClientTestimonalData} from '../../resource/strings/TestimonialData';
import ClientTestimonial from './ClientTestimonial';
import './hearfromclient.style.scss';

interface arrowProps {
  className?: string;
  style?: {};
  onClick?: () => void;
}

/**
 * @param {arrowProps} props
 * @return {Function}
 */
function SampleNextArrow(props: arrowProps) {
  const {className, style, onClick} = props;
  return (
    <div
      className={className}
      style={{
        ...style, display: 'block',
        backgroundImage: `url(${images.ArrowRightWhite})`,
        backgroundSize: '30px 30px', backgroundRepeat: 'norepeat',
        marginRight: '-5px', width: '30px', height: '30px',
      }}
      onClick={onClick}
    />
  );
};

/**
 * @param {arrowProps} props
 * @return {Function}
 */
function SamplePrevArrow(props: arrowProps) {
  const {className, style, onClick} = props;
  return (
    <div
      className={className}
      style={{
        ...style, display: 'block',
        backgroundImage: `url(${images.ArrowLeftWhite})`,
        backgroundSize: '30px 30px', backgroundRepeat: 'norepeat',
        marginLeft: '-5px', width: '30px', height: '30px',
      }}
      onClick={onClick}
    >
    </div>
  );
}

/** @return {Function} */
export default class ClientCarousel extends Component {
  // eslint-disable-next-line require-jsdoc
  render() {
    const settings = {
      className: 'center',
      spaceBetween: 140,
      centerMode: true,
      infinite: true,
      centerPadding: '10px',
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      speed: 400,
      autoplaySpeed: 3000,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            spaceBetween: 120,
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            spaceBetween: 140,
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            spaceBetween: 10,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 1,
            spaceBetween: 10,
            slidesToScroll: 1,
            initialSlide: 1,
            centerPadding: '5px',
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            spaceBetween: 120,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div style={{}}>
        <Slider {...settings} className='client-slider'>
          {ClientTestimonalData.map((item, index) => (
            <ClientTestimonial imgURL={item.imgURL}
              clientName={item.clientName}
              feedback={item.feedback} key={index} />
          ))}
        </Slider>
      </div>
    );
  }
}
