import React from 'react';
import Button from '../../components/button/Button';
import Column from '../../components/Column/Column';
import HeadingText from '../../components/HeadingText/HeadingText';
import Row from '../../components/Row/Row';
import {HomeStrings} from '../../resource/strings/HomeStrings';
import './cta.style.scss';


const CTA = () => {
  return (
    <div className='cta' data-testid='cta-container'>
      <Row className='row'>
        <Column className='col-large-full' innerColClass='col-content center'>
          <HeadingText text={HomeStrings.cta.heading}
            className='cta-heading-text text-center'/>
          <Button title={HomeStrings.cta.btnText}
            to={HomeStrings.cta.btnURL} className='gradient-medium-btn'
            mt='30px' />
        </Column>
      </Row>
    </div>
  );
};

export default CTA;
