import React from 'react';
import './services.scss';
import {motion} from 'framer-motion';
import {images} from '../../resource';
import {Link} from 'react-router-dom';
import {ServicesStrings} from '../../resource/strings/ServicesString';

const Recruiting = () => {
  return (
    <div className='fourway-page'>
      <div style={{marginTop: '50px'}}>
        <Link to='/services'>
          <h3>Go Back</h3>
        </Link>
      </div>
      <div>
        <h1 className='fade-heading'>Our 4-way Process</h1>
      </div>
      <motion.div className='four-way-div'
        initial={{width: 0, opacity: 1}}
        animate={{width: '65vw', opacity: 1}}
        transition={{type: 'spring', duration: 2, delay: 1}}>
        <div>
          <motion.h1
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{ease: 'easeInOut', duration: 3, delay: 2}}>
            {ServicesStrings.fourWayProcess.recruiting.heading}
          </motion.h1>
          <motion.p
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{ease: 'easeInOut', duration: 3, delay: 2}}>
            {ServicesStrings.fourWayProcess.recruiting.desc}
          </motion.p>
        </div>
        <motion.div
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          transition={{ease: 'easeInOut', duration: 3, delay: 2}}
          className='image-div'>
          <img src={images.Recruiting2}
            alt="hr" className='pic' />
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Recruiting;
