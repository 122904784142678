import React from 'react';
import './avartarholder.style.scss';

interface Props {
  initials: string;
}
const AvartarHolder = (props:Props) => {
  return (
    <h6 className='avartarContainer'>{props.initials}</h6>
  );
};

export default AvartarHolder;
