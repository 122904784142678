import HeroImg from './assets/hero_image.png';
import AboutImg from './assets/About_Image.png';
import ArrowLeft from './assets/Arrow---Left-Circle.svg';
import ArrowRight from './assets/Arrow---Right-Circle.svg';
import Logo from './assets/betrworkr_logo.png';
import Client1 from './assets/client1.png';
import Client2 from './assets/client2.png';
import Client3 from './assets/client3.png';
import ContactImg from './assets/contact-img.png';
import HeroImg2 from './assets/hero_image2.png';
import HRRecruitment from './assets/hr_recruitment.png';
import Recruiting from './assets/recruiting.svg';
import Screening from './assets/screening.svg';
import ServicesImage from './assets/services_image1.png';
import ServicesImage2 from './assets/services_image2.png';
import SignInImg from './assets/signin_image.png';
import SignUpImg from './assets/signup_image.png';
import StaffAugImg from './assets/staff_augmentation.png';
import Star from './assets/star.png';
import StrokeCircle from './assets/stroke_circle.png';
import StrokeCircleLong from './assets/stroke-circle-long.png';
import TestingImg from './assets/testing.svg';
import VettingImg from './assets/vetting.svg';
import ServicesVetting from './assets/services_vetting.png';
import ServicesTesting from './assets/services_testing.png';
import ServicesScreening from './assets/services_screening.png';
import ServicesRecruiting from './assets/services_recruiting.png';
import Google from './assets/google.svg';
import Facebook from './assets/Facebook.svg';
import Connectivity from './assets/Connectivity01.png';
import HomeIcon from './assets/Home.png';
import TripploLogo from './assets/tripplologo.svg';
import VentraeLogo from './assets/ventrae-logo.svg';
import VFDGroupLogo from './assets/vfdgroup-logo.png';
import VFDGroupLogo2 from './assets/vfdglogo2.png';
import SharaLogo from './assets/shara-logo.svg';
import SharaLogo2 from './assets/sharalogo2.png';
import OZELogo from './assets/ozelogo.svg';
import OZELogo2 from './assets/ozelogo2.png';
import OkhiLogo from './assets/okhi-logo.svg';
import OkhiLogo2 from './assets/okhilogo2.png';
import MarketForceLogo from './assets/marketforce-logo.png';
import FoundersFactoryLogo from './assets/foundersfactoryafrica.svg';
import FiterLogo from './assets/fiter-logo.svg';
import B54Logo from './assets/B54logo.svg';
import AsaakLogo from './assets/asaaklogo.svg';
import MobilePhone from './assets/Saly-12-001.png';
import WAtom from './assets/Saly-12-002.png';
import Spiral from './assets/Saly-12-003.png';
import SmallCloud from './assets/Saly-12-004.png';
import Capsule from './assets/Saly-12-005.png';
import BigCloud from './assets/Saly-12-006.png';
import TaskBoard from './assets/Saly-13-001.png';
import TaskSpiral from './assets/Saly-13-002.png';
import TaskCube from './assets/Saly-13-003.png';
import Tick from './assets/Tick.png';
import Gear from './assets/Gear.png';
import Screening01 from './assets/Screening-001.png';
import Screening02 from './assets/Screening-002.png';
import Screening03 from './assets/Screening-003.png';
import Screening04 from './assets/Screening-004.png';
import Recruiting01 from './assets/Recriuting-001.png';
import Recruiting02 from './assets/Recriuting-002.png';
import Recruiting03 from './assets/Recriuting-003.png';
import Recruiting04 from './assets/Recriuting-004.png';
import Recruiting05 from './assets/Recriuting-005.png';
import Vetting01 from './assets/Vetting-001.png';
import Vetting02 from './assets/Vetting-002.png';
import Vetting03 from './assets/Vetting-003.png';
import Vetting04 from './assets/Vetting-004.png';
import Vetting05 from './assets/Vetting-005.png';
import Vetting06 from './assets/Vetting-006.png';
import Testing01 from './assets/Testing-001.png';
import Testing02 from './assets/Testing-002.png';
import Testing03 from './assets/Testing-003.png';
import Testing04 from './assets/Testing-004.png';
import Testing05 from './assets/Testing-005.png';
import ArrowLeftWhite from './assets/Arrow-left-white-circle.png';
import ArrowRightWhite from './assets/Arrow-right-white-circle.png';
import TImage1 from './assets/Ellipse16.png';
import TImage2 from './assets/Ellipse17.png';
import TImage3 from './assets/Ellipse18.png';
import TImage4 from './assets/Ellipse19.png';
import TImage5 from './assets/Ellipse20.png';
import TImage6 from './assets/Ellipse21.png';
import Recruiting2 from './assets/Recruiting2.svg';
import Recruiting3 from './assets/recruiting3.png';
import Testing2 from './assets/Testing2.svg';
import Testing3 from './assets/testing3.png';
import Vetting2 from './assets/Vetting2.svg';
import Vetting3 from './assets/vetting3.png';
import Curves from './assets/curves.svg';
import Screening2 from './assets/Screening2.svg';
import Screening3 from './assets/screening3.png';
import StaffAugmentation2 from './assets/staffaugmentation2.png';
import StaffAugmentation3 from './assets/staffaugmentation3.png';
import HRRecruitment3 from './assets/HRrecruitment3.png';
import HRSquare from './assets/HRSquare.svg';
import GirlImage from './assets/girl-image.png';

const images = {
  HeroImg,
  AboutImg,
  ArrowLeft,
  ArrowRight,
  Logo,
  Client1,
  Client2,
  Client3,
  ContactImg,
  HeroImg2,
  HRRecruitment,
  Recruiting,
  Screening,
  ServicesImage,
  ServicesImage2,
  SignInImg,
  SignUpImg,
  StaffAugImg,
  Star,
  StrokeCircle,
  StrokeCircleLong,
  TestingImg,
  VettingImg,
  ServicesScreening,
  ServicesRecruiting,
  ServicesTesting,
  ServicesVetting,
  Google,
  Facebook,
  Connectivity,
  HomeIcon,
  TripploLogo,
  VentraeLogo,
  VFDGroupLogo,
  SharaLogo,
  OZELogo,
  OkhiLogo,
  MarketForceLogo,
  FoundersFactoryLogo,
  FiterLogo,
  B54Logo,
  AsaakLogo,
  MobilePhone,
  WAtom,
  Spiral,
  TaskBoard,
  TaskCube,
  TaskSpiral,
  SmallCloud,
  Capsule,
  BigCloud,
  Tick,
  Gear,
  Screening01,
  Screening02,
  Screening03,
  Screening04,
  Recruiting01,
  Recruiting02,
  Recruiting03,
  Recruiting04,
  Recruiting05,
  Vetting01,
  Vetting02,
  Vetting03,
  Vetting04,
  Vetting05,
  Vetting06,
  Testing01,
  Testing02,
  Testing03,
  Testing04,
  Testing05,
  VFDGroupLogo2,
  OZELogo2,
  OkhiLogo2,
  SharaLogo2,
  ArrowLeftWhite,
  ArrowRightWhite,
  TImage1,
  TImage2,
  TImage3,
  TImage4,
  TImage5,
  TImage6,
  Recruiting2,
  Recruiting3,
  Vetting2,
  Vetting3,
  Testing2,
  Testing3,
  Curves,
  HRSquare,
  StaffAugmentation2,
  StaffAugmentation3,
  HRRecruitment3,
  Screening2,
  Screening3,
  GirlImage,
};
export default images;
