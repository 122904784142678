import React from 'react';
import Contact from '../../containers/Contact/Contact';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import './contactus.style.scss';
import FooterContainer from '../../containers/Footer/FooterContainer';

const ContactUs = () => {
  return (
    <div className='contact-bg' style={{paddingTop: '50px'}}>
      <ScrollToTop />
      <Contact />
      <FooterContainer />
    </div>
  );
};

export default ContactUs;
