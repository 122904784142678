import React from 'react';
import Column from '../../components/Column/Column';
import './homehr.style.scss';
import Row from '../../components/Row/Row';
import {images} from '../../resource';
import Image from '../../components/Image/Image';
import HeadingText from '../../components/HeadingText/HeadingText';
import {HomeStrings} from '../../resource/strings/HomeStrings';
import ParagraphText from '../../components/ParagraphText/ParagraphText';
import LinkButton from '../../components/LinkButton/LinkButton';

const HomeHR = () => {
  return (
    <div data-testid='home-hr-component'>
      <Row className='row'>
        <Column className='col-large-full'>
          <HeadingText text={HomeStrings.homeHr.heading}
            className='heading-text text-center'/>
        </Column>
      </Row>
      <Row className='inner-row inner-row-reverse'>
        <Column className='col' innerColClass='col-content left'>
          <Image imgURL={images.HRRecruitment} altText='HRRecruitment'
            className='image-left' />
        </Column>
        <Column className='col' innerColClass='col-content right'>
          <HeadingText text={HomeStrings.homeHr.hrRecruitment.heading}
            className='subheading-text app__flex-end'/>
          <ParagraphText className='paragraph-text text-right'
            text={HomeStrings.homeHr.hrRecruitment.desc} />
          <LinkButton title='Read more' className='link-btn app__flex-end'
            to={HomeStrings.homeHr.hrRecruitment.btnURL} />
        </Column>
      </Row>
      <Row className='inner-row'>
        <Column className='col' innerColClass='col-content left'>
          <HeadingText text={HomeStrings.homeHr.staffAugmentation.heading}
            className='subheading-text app__flex-start'/>
          <ParagraphText className='paragraph-text text-left'
            text={HomeStrings.homeHr.staffAugmentation.desc} />
          <LinkButton title='Read more' className='link-btn app__flex-start'
            to={HomeStrings.homeHr.staffAugmentation.btnURL} />
        </Column>
        <Column className='col' innerColClass='col-content right'>
          <Image imgURL={images.StaffAugImg} altText='StaffAugmentation'
            className='image-right' />
        </Column>
      </Row>
    </div>
  );
};

export default HomeHR;
