import React from 'react';
import './socialicons.scss';
import {
  GrTwitter,
  GrFacebookOption,
  // GrLinkedinOption,
  // GrYoutube,
} from 'react-icons/gr';
import {AiFillInstagram} from 'react-icons/ai';
import {FooterStrings} from '../../resource/strings/FooterStrings';

const SocialIcons = () => {
  return (
    <div className="footer__social" data-testid="social-icons-component">
      <h3>{FooterStrings.social.heading}</h3>
      <ul className="footer__col-social">
        <li>
          <a
            href={FooterStrings.social.twitter.to}
            target="_blank"
            rel="noreferrer"
          >
            <GrTwitter />
          </a>
        </li>
        <li>
          <a
            href={FooterStrings.social.instagram.to}
            target="_blank"
            rel="noreferrer"
          >
            <AiFillInstagram />
          </a>
        </li>
        <li>
          <a
            href={FooterStrings.social.facebook.to}
            target="_blank"
            rel="noreferrer"
          >
            <GrFacebookOption />
          </a>
        </li>
        {/* <li>
          <a
            href={FooterStrings.social.linkedin.to}
            target="_blank"
            rel="noreferrer"
          >
            <GrLinkedinOption />
          </a>
        </li> */}
        {/* <li>
          <a
            href={FooterStrings.social.youtube.to}
            target="_blank"
            rel="noreferrer"
          >
            <GrYoutube />
          </a>
        </li> */}
      </ul>
    </div>
  );
};

export default SocialIcons;
