import React from 'react';
import TextInput from '../TextInput';
import '../../button/button.style.scss';

const SignUpForm = () => {
  return (
    <form data-testid="signup-form-component">
      <div className="contact__inner-row">
        <div className="contact__col left">
          <TextInput
            labelName="First Name*"
            inputType="text"
            name="firstName"
            required={true}
          />
        </div>
        <div className="contact__col right">
          <TextInput labelName="Last Name" inputType="text" name="lastName" />
        </div>
      </div>
      <div className="contact__inner-row">
        <TextInput
          labelName="Email*"
          inputType="email"
          name="email"
          required={true}
        />
      </div>
      <div className="contact__inner-row">
        <TextInput labelName="Phone Number" inputType="tel" name="phonenum" />
      </div>
      <div className="contact__inner-row">
        <TextInput labelName="Password" inputType="password" name="password" />
      </div>
      <div className="contact__inner-row">
        <TextInput
          labelName="Confirm Password"
          inputType="password"
          name="confirmPassword"
        />
      </div>
      <div className="contact__inner-row">
        <input type="submit" value="Sign Up" className="gradient-large-btn" />
      </div>
    </form>
  );
};

export default SignUpForm;
