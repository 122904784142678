import React, {useState} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Column from '../../components/Column/Column';
import HeadingText from '../../components/HeadingText/HeadingText';
import Row from '../../components/Row/Row';
import './clientstories.style.scss';
import {ClientTestimonalData2}
  from '../../resource/strings/TestimonialData';

/** @return {Function} */
export default function ClientStories() {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const settings = {
    className: 'center',
    spaceBetween: 140,
    centerMode: true,
    infinite: true,
    centerPadding: '10px',
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 400,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          spaceBetween: 120,
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          spaceBetween: 140,
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          spaceBetween: 10,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
          spaceBetween: 10,
          slidesToScroll: 1,
          initialSlide: 1,
          centerPadding: '5px',
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          spaceBetween: 120,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settings2 = {
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <div>
      <Row className='row'>
        <Column className='col-large-full'>
          <HeadingText text='What our Clients are saying'
            className='subheading-text text-center' />
        </Column>
      </Row>
      <div className='row-slide'>
        <Slider
          asNavFor={nav2}
          ref={(slider1: any) => setNav1(slider1)}
          swipeToSlide={true}
          focusOnSelect={true}
          {...settings}
          className='client-stories-slider'
        >
          {ClientTestimonalData2.map((item) => (
            <div key={item.clientName}>
              <div className='image-box'>
                <img src={item.imgURL} alt={item.clientName} />
              </div>
            </div>
          ))}
        </Slider>
        <div className='slide-container'>
          <div className='shadow1'></div>
          <div className='shadow2'></div>
          <Slider asNavFor={nav1} ref={(slider2: any) => setNav2(slider2)}
            {...settings2} className='client-feedback-slider'>
            {ClientTestimonalData2.map((item) => (
              <div key={item.clientName}>
                <div className='feedback-container'>
                  <div>
                    <p>{item.feedback}</p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}
