import React from 'react';
import Column from '../../components/Column/Column';
import HeadingText from '../../components/HeadingText/HeadingText';
import Row from '../../components/Row/Row';
import TestimonialCarousel from './TestimonialCarousel';
import './testimonialstories.style.scss';

const TestimonialsStories = () => {
  return (
    <div>
      <Row className='row hero'>
        <Column className='col-large-full'>
          <HeadingText text='Our Candidates love our Services'
            className='subheading-text text-center'/>
        </Column>
      </Row>
      <Row className='inner-row'>
        <TestimonialCarousel />
      </Row>
    </div>
  );
};

export default TestimonialsStories;
