import React from 'react';
import FooterContact from '../../components/FooterContact/FooterContact';
import FooterLinks from '../../components/FooterLinks/FooterLinks';
import SocialIcons from '../../components/SocialIcons/SocialIcons';
import {FooterStrings} from '../../resource/strings/FooterStrings';
import './footercontainer.style.scss';


const FooterContainer = () => {
  const today = new Date();
  return (
    <footer className='app__footer' data-testid='footer-container'>
      <div className="footer__row">
        <div className="footer__col">
          <FooterLinks heading={FooterStrings.company.heading}
            footerData={FooterStrings.company.links} />
        </div>
        <div className="footer__col">
          <FooterLinks heading={FooterStrings.legal.heading}
            footerData={FooterStrings.legal.links} />
        </div>
        <div className="footer__col">
          <FooterContact heading={FooterStrings.contact.heading}
            contactDesc={FooterStrings.contact.desc} />
        </div>
        <div className="footer__col2">
          <SocialIcons />
        </div>
      </div>
      <hr />
      <div className="footer__row">
        <p>
          Copyright &copy; {today.getFullYear()} Betrworkr. All rights reserved
        </p>
      </div>
    </footer>
  );
};

export default FooterContainer;
