import React, {useState, useEffect} from 'react';
import Column from '../../components/Column/Column';
import HeadingText from '../../components/HeadingText/HeadingText';
import Row from '../../components/Row/Row';
import './clientsection.styles.scss';
import {ClientsData} from '../../resource/strings/ClientStrings';
import {ClientsDataMob} from '../../resource/strings/ClientStrings';
import Marquee from 'react-fast-marquee';

const ClientSection3 = () => {
  const [clientRow1, setClientRow1] = useState(ClientsData.row1);
  const [clientRow2, setClientRow2] = useState(ClientsData.row2);
  const [clientMobRow1, setClientMobRow1] = useState(ClientsDataMob.row1);
  const [clientMobRow2, setClientMobRow2] = useState(ClientsDataMob.row2);
  const [clientMobRow3, setClientMobRow3] = useState(ClientsDataMob.row3);

  useEffect(() => {
    setClientRow1(clientRow1);
    setClientRow2(clientRow2);
    setClientMobRow1(clientMobRow1);
    setClientMobRow2(clientMobRow2);
    setClientMobRow3(clientMobRow3);
  }, [clientRow1, clientRow2, clientMobRow1, clientMobRow2, clientMobRow3]);
  return (
    <div>
      <Row className='inner-row'>
        <Column className='col-large-full'>
          <HeadingText text='Companies we have worked with'
            className='subheading-text text-center'/>
        </Column>
      </Row>
      <Row className='row-space-between'>
        <Marquee gradientWidth={10} direction='left'>
          {clientRow1.map((item) => (
            <div key={item.altText} style={{padding: '0px 25px'}}>
              <img src={item.imgURL}
                alt={item.altText} className='clientLogo3' />
            </div>),
          )}
        </Marquee>
      </Row>
      <Row className='row-space-between'>
        <Marquee gradientWidth={10} direction='right'>
          {clientRow2.map((item) => (
            <div key={item.altText} style={{padding: '0px 30px'}}>
              <img src={item.imgURL}
                alt={item.altText} className='clientLogo3' />
            </div>
          ),
          )}
        </Marquee>
      </Row>
    </div>
  );
};

export default ClientSection3;
