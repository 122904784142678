export const NavData = [
  {
    path: '/',
    name: 'Home',
    imgURL: require('../../resource/assets/Home.png'),
  },
  {
    path: '/about-us',
    name: 'About Us',
    imgURL: require('../../resource/assets/Info-Square.png'),
  },
  {
    path: '/services',
    name: 'Services',
    imgURL: require('../../resource/assets/Chat.png'),
  },
  {
    path: '/testimonials',
    name: 'Testimonials',
    imgURL: require('../../resource/assets/Chat.png'),
  },
  {
    path: '/contact',
    name: 'Contact',
    imgURL: require('../../resource/assets/Call.png'),
  },
];
