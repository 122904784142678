import React from 'react';
import './services.scss';
import {motion} from 'framer-motion';
import {images} from '../../resource';
import {Link} from 'react-router-dom';
import {ServicesStrings} from '../../resource/strings/ServicesString';

const SAUG = () => {
  return (
    <div className='sa-page'>
      <div style={{marginTop: '50px'}}>
        <Link to='/services'>
          <h3>Go Back</h3>
        </Link>
      </div>
      <div>
        <h1 className='fade-heading'>Our core services</h1>
      </div>
      <div className='sa-div'>
        <div className='sa'>
          <motion.h1
            initial={{opacity: 0, y: -20}}
            animate={{opacity: 1, y: 0}}
            transition={{ease: 'easeInOut', duration: 1, delay: 1}}>
            {ServicesStrings.serviceOffering.staffAugmentation.heading}
          </motion.h1>
          <motion.p
            initial={{opacity: 0, y: 20}}
            animate={{opacity: 1, y: 0}}
            transition={{ease: 'easeInOut', duration: 1, delay: 1}}>
            {ServicesStrings.serviceOffering.staffAugmentation.desc}
          </motion.p>
        </div>
        <motion.div
          initial={{opacity: 0, height: 0, x: 0}}
          animate={{opacity: 1, height: '100%', x: -70}}
          transition={{ease: 'easeInOut', duration: 2, delay: 1.5}}
          className='img-div'>
          <img src={images.StaffAugmentation3}
            alt="saug" style={{textAlign: 'right'}} />
        </motion.div>
      </div>
    </div>
  );
};

export default SAUG;
