import React from 'react';
import Column from '../../components/Column/Column';
import HeadingText from '../../components/HeadingText/HeadingText';
import ParagraphText from '../../components/ParagraphText/ParagraphText';
import Row from '../../components/Row/Row';
import {HomeStrings} from '../../resource/strings/HomeStrings';
import Image from '../../components/Image/Image';
import {images} from '../../resource';
import '../../components/SocialIcons/socialicons.scss';
import {
  GrTwitter,
  GrFacebookOption,
} from 'react-icons/gr';
import {AiFillInstagram} from 'react-icons/ai';
import {FooterStrings} from '../../resource/strings/FooterStrings';
// import ContactForm from '../../components/FormElements/ContactForm/ContactForm';

const Contact = () => <div data-testid='contact-container'>
  <Row className='inner-row'>
    <Column className='col' innerColClass='col-content left'>
      <HeadingText text={HomeStrings.contact.heading}
        className='subheading-text text-left' />
      <ParagraphText className='paragraph-text text-left'
        text={HomeStrings.contact.desc} />
      <div className='contact-details'>
        {/* <ContactForm /> */}
        <h2 style={{marginBottom: '10px'}}>Contact us via Email:</h2>
        <a href={`mailto:hello@betrworkr.com`}><h3>hello@betrworkr.com</h3></a>
        <h2 style={{marginTop: '20px'}}>Contact us via Social media:</h2>
        <div>
          <ul className="contact-social">
            <li>
              <a
                href={FooterStrings.social.twitter.to}
                target="_blank"
                rel="noreferrer"
              >
                <GrTwitter />
              </a>
            </li>
            <li>
              <a
                href={FooterStrings.social.instagram.to}
                target="_blank"
                rel="noreferrer"
              >
                <AiFillInstagram />
              </a>
            </li>
            <li>
              <a
                href={FooterStrings.social.facebook.to}
                target="_blank"
                rel="noreferrer"
              >
                <GrFacebookOption />
              </a>
            </li>
            {/* <li>
          <a
            href={FooterStrings.social.linkedin.to}
            target="_blank"
            rel="noreferrer"
          >
            <GrLinkedinOption />
          </a>
        </li> */}
            {/* <li>
          <a
            href={FooterStrings.social.youtube.to}
            target="_blank"
            rel="noreferrer"
          >
            <GrYoutube />
          </a>
        </li> */}
          </ul>
        </div>
      </div>
    </Column>
    <Column className='col' innerColClass='col-content right'>
      <Image imgURL={images.ContactImg} altText='StaffAugmentation'
        className='image-right' />
    </Column>
  </Row>
</div>;

export default Contact;
