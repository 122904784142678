import React, {Component} from 'react';
import './testimonials.style.scss';
import '../../../node_modules/react-slick/';
import Slider from 'react-slick';
import {TestimonialsData} from '../../resource/strings/TestimonialData';
import Testimonial from '../../components/Testimonial/Testimonial';
import Column from '../../components/Column/Column';
import Row from '../../components/Row/Row';
import HeadingText from '../../components/HeadingText/HeadingText';

/** @return {Function} */
export default class Testimonials extends Component {
  /** @return {Function} */
  render() {
    const settings = {
      spaceBetween: 140,
      dots: false,
      infinite: true,
      speed: 400,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            spaceBetween: 120,
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            spaceBetween: 140,
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            spaceBetween: 120,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 1,
            spaceBetween: 120,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            spaceBetween: 120,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div data-testid='testimonials-container'>
        <Row className='row'>
          <Column className='col-large-full'>
            <HeadingText text='What people are saying'
              className='subheading-text text-center' />
          </Column>
        </Row>
        <Row className='inner-row bg-lightblue'>
          <Slider {...settings} className="sliderWrapper">
            {TestimonialsData.length &&
              TestimonialsData.map((items, index) => (
                <Testimonial
                  imgURL={items.imgURL}
                  clientName={items.clientName}
                  jobDesc={items.jobDesc}
                  feedback={items.feedback}
                  key={`testimonial${index}`}
                />
              ))}
          </Slider>
        </Row>
      </div>
    );
  }
}
