import React from 'react';
import Home from './Home/Home';
import AboutUs from './AboutUs/AboutUs';
import Services from './Services/Services';
import ContactUs from './ContactUs/ContactUs';
import SignUp from './SignUp/SignUp';
import LogIn from './LogIn/LogIn';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import NavContainer from '../containers/NavContainer/NavContainer';
// import FooterContainer from '../containers/Footer/FooterContainer';
import NotFoundPage from './NotFoundPage/NotFoundPage';
import TestimonialsPage from './TestimonialsPage/TestimonialsPage';
import HRR from './Services/HRR';
import SAUG from './Services/SAUG';
import Screen from './Services/Screen';
import Testing from './Services/Testing';
import Vetting from './Services/Vetting';
import Recruiting from './Services/Recruiting';

export {Home};
const Pages = () => {
  return (
    <BrowserRouter>
      <NavContainer />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/log-in" element={<LogIn />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/testimonials" element={<TestimonialsPage />} />
        <Route path="/hrr" element={<HRR />} />
        <Route path="/saug" element={<SAUG />} />
        <Route path="/screening" element={<Screen />} />
        <Route path="/testing" element={<Testing />} />
        <Route path="/vetting" element={<Vetting />} />
        <Route path="/recruiting" element={<Recruiting />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Pages;
