import React, {useState} from 'react';
import './navcontainer.style.scss';
// import Button from '../../components/button/Button';
import Logo from '../../components/Logo/Logo';
import {images} from '../../resource';
import {NavData} from '../../components/NavLinks/NavData';
import NavLinks from '../../components/NavLinks/NavLinks';
import MobileMenu from '../../components/MobileMenu/MobileMenu';

const NavContainer = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <nav className="app__navbar">
      <Logo to="/" imgURL={images.Logo} altText="Logo" />
      <NavLinks navData={NavData} className="app__navbar-links" />

      {/* <div className="app__navbar-login-signup">
        <Button to="/log-in" title="Log In" className="secondary-small-btn" />
        <Button
          to="/sign-up"
          title="Sign Up"
          className="primary-small-btn"
          ml="20px"
        />
      </div> */}
      <MobileMenu
        navData={NavData}
        className="app__navbar-smallscreen_links"
        menuClick={() => setToggleMenu(true)}
        closeMenuClick={() => setToggleMenu(false)}
        toggleMenu={toggleMenu}
        handleClick={() => setToggleMenu(false)}
      />
    </nav>
  );
};

export default NavContainer;
