import React, {HTMLAttributes} from 'react';
import './column.style.scss';

export interface columnProps extends HTMLAttributes<HTMLDivElement> {

  /** This takes the children of the column component */
  children: JSX.Element[] | JSX.Element;
  /** This takes the className of the outer column */
  className: string;
  /** This takes the className of the inner column */
  innerColClass?: string;
}

/** @return {Funtion} This is the `column component` */
const Column = ({children, className, innerColClass}: columnProps) => {
  return (
    <div className={className} data-testid="column-component">
      <div className={innerColClass}>
        {children}
      </div>
    </div>
  );
};

export default Column;
