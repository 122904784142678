export const HomeStrings = {
  hero: {
    heroHeading: 'We make your hiring process easy',
    heroDesc: 'Hire your next rockstar',
    herobtn: {
      text: 'Get Started',
      URL: '/contact',
    },
  },
  homeHr: {
    heading: 'We are a human resource start-up that leverages on technology',
    hrRecruitment: {
      heading: 'HR Recruitment',
      desc: 'Finding the perfect employees can be time consuming, at ' +
      'Betrworkr we ensure that organizations recruit the best talent into' +
      'vacant positions without any rigorous procedures.',
      btnURL: '/services',
    },
    staffAugmentation: {
      heading: 'Staff Augmentation',
      desc: 'The constant evolution of technology and business needs' +
      ' leaves the demands on personnel highly susceptible to change;' +
      ' our staff augmentation model enables organizations to adapt in' +
      ' effective and efficient ways.',
      btnURL: '/services',
    },
  },
  HomeFourWay: {
    heading: 'Our 4-way Process',
    screening: {
      heading: '1. Screening',
      desc: 'The screening process begins with the review of a candidate\'s' +
      ' employment application and resume followed by a telephone interview.',
      btnURL: '/services',
    },
    vetting: {
      heading: '2. Vetting',
      desc: 'The vetting process allows us to verify information the job ' +
      'candidates have provided during the application process, from '+
      'credentials to professional references.',
      btnURL: '/services',
    },
    testing: {
      heading: '3. Testing',
      desc: 'The purpose of testing is to help predict how well an '+
      'individual will perform on the job.',
      btnURL: '/services',
    },
    recruiting: {
      heading: '4. Recruiting',
      desc: 'With our thorough processes, you are rest assured '+
      'knowing that only top notch talent is delivered to our clients'+
      ' to begin their interview process.',
      btnURL: '/services',
    },
  },
  cta: {
    heading: 'Hire your next rockstar',
    btnURL: '/contact',
    btnText: 'Get Started',
  },
  contact: {
    heading: 'Get in touch with us',
    desc: 'Our friendly team would love to hear from you!',
  },
};
