import * as React from 'react';
import images from '../images';

const VettingSVG = (props) => (
  <div className='vettingsvg-container'>
    <img src={images.Vetting01} alt='Vetting1' id='vetting01' />
    <img src={images.Vetting02} alt='Vetting2' id='candidate1' />
    <img src={images.Vetting03} alt='Vetting3' id='candidate3' />
    <img src={images.Vetting04} alt='Vetting4' id='candidate2' />
    <img src={images.Vetting05} alt='Vetting5' id='circle1' />
    <img src={images.Vetting06} alt='Vetting6' id='circle2' />
  </div>
);

export default VettingSVG;
