import React, {useState} from 'react';
import './services.scss';
import Particles from 'react-tsparticles';
import {loadFull} from 'tsparticles';
import {images} from '../../resource';
import {Link} from 'react-router-dom';
import {motion} from 'framer-motion';

const Services = () => {
  const [bg, setBg] = useState('default');
  const particlesInit = async (main: any) => {
    console.log(main);
    await loadFull(main);
  };

  return (
    <div>
      <div className={`serv ${bg}`}>
        <Particles
          id="tsparticles"
          init={particlesInit}
          style={{zIndex: 2, height: '300px'}}
          options={{
            background: {
              color: {
                value: 'transparent',
              },
            },
            fpsLimit: 120,
            interactivity: {
              events: {
                onClick: {
                  enable: true,
                  mode: 'push',
                },
                onHover: {
                  enable: true,
                  mode: 'repulse',
                },
                resize: true,
              },
              modes: {
                push: {
                  quantity: 4,
                },
                repulse: {
                  distance: 500,
                  duration: 5,
                  maxSpeed: 1,
                },
              },
            },
            particles: {
              color: {
                value: '#03A0FD',
              },
              links: {
                color: '#FF9B3F',
                distance: 150,
                enable: true,
                opacity: 0.5,
                width: 1,
              },
              collisions: {
                enable: true,
              },
              move: {
                direction: 'none',
                enable: true,
                outModes: {
                  default: 'bounce',
                },
                random: false,
                speed: 6,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  area: 800,
                },
                value: 80,
              },
              opacity: {
                value: 0.5,
              },
              shape: {
                type: 'circle',
              },
              size: {
                value: {min: 1, max: 5},
              },
            },
            detectRetina: true,
          }}
        />
        <div className='service-row'>
          <div className='core-service'>
            <motion.h1 className='stroke-text'
              initial={{y: -20}}
              animate={{y: 0}}
              transition={{ease: 'easeIn', duration: 1}}>Our core</motion.h1>
            <motion.h1
              initial={{y: 20}}
              animate={{y: 0}}
              transition={{ease: 'easeIn', duration: 1}}
            >service offering</motion.h1>
            <Link to='/hrr'>
              <motion.div className='hr-div floating'
                onMouseOver={() => setBg('hrr')}
                onMouseLeave={() => setBg('default')}
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                transition={{ease: 'easeIn', duration: 0.5}}>
                <h3>HR Recruitment</h3>
              </motion.div>
            </Link>
            <Link to='/saug'>
              <motion.div className='staff-div floating'
                onMouseOver={() => setBg('saug')}
                onMouseLeave={() => setBg('default')}
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                transition={{ease: 'easeIn', duration: 0.5, delay: 1}}>
                <h3>Staff Augmentation</h3>
              </motion.div>
            </Link>
          </div>
          <div className='four-way'>
            <motion.h1 className='stroke-text2'
              initial={{y: -20}}
              animate={{y: 0}}
              transition={{ease: 'easeIn', duration: 1}}>Our</motion.h1>
            <motion.h1
              initial={{y: 20}}
              animate={{y: 0}}
              transition={{ease: 'easeIn', duration: 1}}>
              4-way Process</motion.h1>
            <Link to='/screening'>
              <motion.div className='screening-div floating'
                onMouseOver={() => setBg('screen')}
                onMouseLeave={() => setBg('default')}
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                transition={{ease: 'easeIn', duration: 0.5, delay: 2}}>
                <h3>Screening</h3>
              </motion.div>
            </Link>
            <Link to='/testing'>
              <motion.div className='testing-div floating'
                onMouseOver={() => setBg('test')}
                onMouseLeave={() => setBg('default')}
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                transition={{ease: 'easeIn', duration: 0.5, delay: 3}}>
                <h3>Testing</h3>
              </motion.div>
            </Link>
            <Link to='/vetting'>
              <motion.div className='vetting-div floating'
                onMouseOver={() => setBg('vet')}
                onMouseLeave={() => setBg('default')}
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                transition={{ease: 'easeIn', duration: 0.5}}>
                <h3>Vetting</h3>
              </motion.div>
            </Link>
            <Link to='/recruiting'>
              <motion.div className='recruiting-div floating'
                onMouseOver={() => setBg('recruit')}
                onMouseLeave={() => setBg('default')}
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                transition={{ease: 'easeIn', duration: 0.5, delay: 1}}>
                <h3>Recruiting</h3>
              </motion.div>
            </Link>
          </div>
        </div>
        <img src={images.GirlImage} alt="girl-image" className='girl-image' />
      </div>
    </div>
  );
};

export default Services;
