import React, {HTMLAttributes} from 'react';
import './logo.style.scss';
import {Link} from 'react-router-dom';

export interface logoProps extends HTMLAttributes<HTMLImageElement> {
  /** This takes the URL of the link the `Logo` links to */
  to: any;
  /** This takes the Logo image URL */
  imgURL: string;
  /** This shows the image alt text */
  altText: string;
}

/** @return {Funtion} This is the `Logo` */
const Logo = ({to, imgURL, altText}: logoProps) => {
  return (
    <div className="logo">
      <Link to={to} data-testid="logo-component">
        <img src={imgURL} alt={altText} />
      </Link>
    </div>
  );
};

export default Logo;
