import React from 'react';
import {Provider} from 'react-redux';
import './App.css';
import Pages from './pages';
import {store} from './redux/store';

/** @return {Function} */
function App() {
  return (
    <Provider store={store}>
      <Pages />
    </Provider>
  );
}

export default App;
