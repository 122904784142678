import React from 'react';
import Column from '../../components/Column/Column';
import HeadingText from '../../components/HeadingText/HeadingText';
import Row from '../../components/Row/Row';
import {NotFoundStrings} from '../../resource/strings/NotFoundString';
import Image from '../../components/Image/Image';
import {images} from '../../resource';
import ParagraphText from '../../components/ParagraphText/ParagraphText';
import Button from '../../components/button/Button';

const NotFound = () => (
  <div data-testid='notfound-container'>
    <Row className='row hero2'>
      <Column className='content-center'>
        <Image imgURL={images.Connectivity} altText='Connectivity'
          className='image-center'/>
        <HeadingText text={NotFoundStrings.heading}
          className='heading-text text-center' />
        <ParagraphText text={NotFoundStrings.desc}
          className='paragraph-text text-center' />
        <Button title={NotFoundStrings.buttonText}
          to={NotFoundStrings.buttonURL}
          className='gradient-medium-btn' mt='20px' />
      </Column>
    </Row>
  </div>
);

export default NotFound;
