import React from 'react';
import Column from '../../components/Column/Column';
import Row from '../../components/Row/Row';
import HeadingText from '../../components/HeadingText/HeadingText';
import {HomeStrings} from '../../resource/strings/HomeStrings';
import ParagraphText from '../../components/ParagraphText/ParagraphText';
import LinkButton from '../../components/LinkButton/LinkButton';
import ScreeningSVG from '../../resource/SVGs/ScreeningSVG';
import {motion} from 'framer-motion';
import TestingSVG from '../../resource/SVGs/TestingSVG';
import VettingSVG from '../../resource/SVGs/VettingSVG';
import RecruitingSVG from '../../resource/SVGs/RecruitingSVG';

const HomeFourWayProcess = () => {
  return (
    <div data-testid='home-fourway-component'>
      <Row className='row'>
        <Column className='col-large-full'>
          <HeadingText text={HomeStrings.HomeFourWay.heading}
            className='heading-text text-center'/>
        </Column>
      </Row>
      <Row className='inner-row inner-row-reverse'>
        <Column className='col' innerColClass='col-content left'>
          <motion.div
            whileInView={{x: [0, 40]}}
            transition={{ease: 'easeOut', duration: 2}}>
            <ScreeningSVG />
          </motion.div>
        </Column>
        <Column className='col' innerColClass='col-content right'>
          <HeadingText text={HomeStrings.HomeFourWay.screening.heading}
            className='subheading-text app__flex-end'/>
          <ParagraphText className='paragraph-text text-right'
            text={HomeStrings.HomeFourWay.screening.desc} />
          <LinkButton title='Read more' className='link-btn app__flex-end'
            to={HomeStrings.HomeFourWay.screening.btnURL} />
        </Column>
      </Row>
      <Row className='inner-row'>
        <Column className='col' innerColClass='col-content left'>
          <HeadingText text={HomeStrings.HomeFourWay.vetting.heading}
            className='subheading-text app__flex-start'/>
          <ParagraphText className='paragraph-text text-left'
            text={HomeStrings.HomeFourWay.vetting.desc} />
          <LinkButton title='Read more' className='link-btn app__flex-start'
            to={HomeStrings.HomeFourWay.vetting.btnURL} />
        </Column>
        <Column className='col' innerColClass='col-content right'>
          <motion.div
            whileInView={{x: [0, -30]}}
            transition={{ease: 'easeOut', duration: 2}}>
            <VettingSVG />
          </motion.div>
        </Column>
      </Row>
      <Row className='inner-row inner-row-reverse'>
        <Column className='col' innerColClass='col-content left'>
          <motion.div
            whileInView={{x: [0, 40]}}
            transition={{ease: 'easeOut', duration: 2}}>
            <TestingSVG />
          </motion.div>
        </Column>
        <Column className='col' innerColClass='col-content right'>
          <HeadingText text={HomeStrings.HomeFourWay.testing.heading}
            className='subheading-text app__flex-end'/>
          <ParagraphText className='paragraph-text text-right'
            text={HomeStrings.HomeFourWay.testing.desc} />
          <LinkButton title='Read more' className='link-btn app__flex-end'
            to={HomeStrings.HomeFourWay.testing.btnURL} />
        </Column>
      </Row>
      <Row className='inner-row'>
        <Column className='col' innerColClass='col-content left'>
          <HeadingText text={HomeStrings.HomeFourWay.recruiting.heading}
            className='subheading-text app__flex-start'/>
          <ParagraphText className='paragraph-text text-left'
            text={HomeStrings.HomeFourWay.recruiting.desc} />
          <LinkButton title='Read more' className='link-btn app__flex-start'
            to={HomeStrings.HomeFourWay.recruiting.btnURL} />
        </Column>
        <Column className='col' innerColClass='col-content right'>
          <motion.div
            whileInView={{x: [0, -30]}}
            transition={{ease: 'easeOut', duration: 2}}>
            <RecruitingSVG />
          </motion.div>
        </Column>
      </Row>
    </div>
  );
};

export default HomeFourWayProcess;
