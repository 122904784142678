export const FooterStrings = {
  company: {
    heading: 'Company',
    links: [
      {
        name: 'FAQs',
        to: '/',
      },
      {
        name: 'About Us',
        to: '/about-us',
      },
      {
        name: 'Blog',
        to: '/',
      },
    ],
  },
  legal: {
    heading: 'Legal',
    links: [
      {
        name: 'Privacy',
        to: '/',
      },
      {
        name: 'Terms',
        to: '/',
      },
    ],
  },
  contact: {
    heading: 'contact',
    desc: 'hello@betrworkr.com',
  },
  social: {
    heading: 'Social',
    twitter: {
      to: 'https://twitter.com/betrworkr',
    },
    instagram: {
      to: 'https://www.instagram.com/betrworkr/',
    },
    facebook: {
      to: 'https://web.facebook.com/Betrworkr-108379695023638',
    },
    linkedin: {
      to: '/',
    },
    youtube: {
      to: '/',
    },
  },
};
