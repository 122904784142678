import React, {HTMLAttributes} from 'react';
import './paragraphtext.style.scss';

export interface paragraphTextProp extends HTMLAttributes<HTMLParagraphElement>{
  /** This takes the text */
  text: string;
  /** This takes the className */
  className: string;
  }

/** @return {Funtion} This is the `Paragraph text component` */
const ParagraphText = ({text, className}: paragraphTextProp) => {
  return (
    <div className={className} data-testid="paragraph-text-test">
      <p>{text}</p>
    </div>
  );
};

export default ParagraphText;
