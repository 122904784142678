import React from 'react';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import FooterContainer from '../../containers/Footer/FooterContainer';
import SignUpSection from '../../containers/SignUpSection/SignUpSection';
import './signup.styles.scss';

const SignUp = () => {
  return <div className='signup-bg'>
    <ScrollToTop />
    <SignUpSection />
    <FooterContainer />
  </div>;
};

export default SignUp;
