import React from 'react';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import ClientStories from '../../containers/ClientStories/ClientStories';
// import ContactSec from '../../containers/ContactSec/ContactSec';
import CTA from '../../containers/CTA/CTA';
import FooterContainer from '../../containers/Footer/FooterContainer';
import TestimonialsStories
  from '../../containers/TestimonialsStories/TestimonialsStories';
import './testimonials.styles.scss';

const TestimonialsPage = () => {
  return (
    <div className='testimonials-bg'>
      <ScrollToTop />
      <TestimonialsStories />
      <ClientStories />
      <CTA />
      {/* <ContactSec /> */}
      <FooterContainer />
    </div>
  );
};

export default TestimonialsPage;
