import images from '../images';

export const TestimonialsData = [
  {
    clientName: 'Oladele A',
    imgURL: '',
    jobDesc: 'Full Stack Mobile Developer',
    feedback:
      'I have landed my current role thanks to Betrworkr. They'+
      ' were incredibly diligent in ensuring the job and company'+
      ' culture that applied for was perfect for me. Their '+
      'professionalism, attention to detail and responsiveness ' +
      'was second to none and they helped me get to a much better'+
      ' position and salary.  Massive appreciation to the team, '+
      'thank you again!',
  },
  {
    clientName: 'Morakinyo A',
    imgURL: '',
    jobDesc: 'Product Design Lead',
    feedback:
      'My recruitment experience was seamless, the team is super '+
      'responsive, helpful at all times and it was a pleasure working '+
      'with them. I was first on a staff augmentation contract to ensure'+
      ' a mutual fit between the client and I then got directly hired by '+
      'the health tech company some months later.',
  },
  {
    clientName: 'Sani S',
    imgURL: '',
    jobDesc: 'Engineering Manager',
    feedback:
    'Betrworkr was awesome all through my recruitment process. I have '+
    'never met such a  dedicated and professional recruitment team. The '+
    'team was able to efficiently manage expectations, communicate effectively'+
    ' and manage feedbacks. I can not thank the team enough for helping me '+
    'start a new chapter of my professional career.',
  },
  {
    clientName: 'Emike O',
    imgURL: '',
    jobDesc: '',
    feedback:
      'My recruitment experience with Betrworkr was really an amazing one, I '+
      'could genuinely feel my best interest was their concern in terms of '+
      'finding me an employer. I was carried along every step of the '+
      'recruitment process, all my concerns and questions were welcome and'+
      ' given due attention, sometimes even at their inconvenience. I would '+
      'be certainly maintaining this relationship.',
  },
  {
    clientName: 'Tuoyo M',
    imgURL: '',
    jobDesc: 'Business Analyst',
    feedback:
      'My recruitment experience was seamless, the team is super '+
      'responsive, helpful at all times and it was a pleasure working '+
      'with them. I was first on a staff augmentation contract to ensure'+
      ' a mutual fit between the client and I then got directly hired by '+
      'the health tech company some months later.',
  },
  {
    clientName: 'Beriakuma D',
    imgURL: '',
    jobDesc: 'Product Specialist',
    feedback:
    'Betrworkr was awesome all through my recruitment process. I have '+
    'never met such a  dedicated and professional recruitment team. The '+
    'team was able to efficiently manage expectations, communicate effectively'+
    ' and manage feedbacks. I can not thank the team enough for helping me '+
    'start a new chapter of my professional career.',
  },
];

export const TestimonialsData3 = [
  {
    clientName: 'Oladele A',
    imgURL: images.TImage1,
    position: 1,
    jobDesc: 'Full Stack Mobile Developer',
    id: 0,
    alt: 'Image1',
    feedback:
      'I have landed my current role thanks to Betrworkr. They'+
      ' were incredibly diligent in ensuring the job and company'+
      ' culture that applied for was perfect for me. Their '+
      'professionalism, attention to detail and responsiveness ' +
      'was second to none and they helped me get to a much better'+
      ' position and salary.  Massive appreciation to the team, '+
      'thank you again!',
  },
  {
    clientName: 'Morakinyo A',
    imgURL: images.TImage2,
    position: 2,
    jobDesc: 'Product Design Lead',
    id: 1,
    alt: 'Image2',
    feedback:
      'My recruitment experience was seamless, the team is super '+
      'responsive, helpful at all times and it was a pleasure working '+
      'with them. I was first on a staff augmentation contract to ensure'+
      ' a mutual fit between the client and I then got directly hired by '+
      'the health tech company some months later.',
  },
  {
    clientName: 'Sani S',
    imgURL: images.TImage3,
    position: 3,
    jobDesc: 'Engineering Manager',
    id: 2,
    alt: 'Image3',
    feedback:
    'Betrworkr was awesome all through my recruitment process. I have '+
    'never met such a  dedicated and professional recruitment team. The '+
    'team was able to efficiently manage expectations, communicate effectively'+
    ' and manage feedbacks. I can not thank the team enough for helping me '+
    'start a new chapter of my professional career.',
  },
  {
    clientName: 'Emike O',
    imgURL: images.TImage4,
    position: 4,
    jobDesc: '',
    id: 3,
    alt: 'Image4',
    feedback:
      'My recruitment experience with Betrworkr was really an amazing one, I '+
      'could genuinely feel my best interest was their concern in terms of '+
      'finding me an employer. I was carried along every step of the '+
      'recruitment process, all my concerns and questions were welcome and'+
      ' given due attention, sometimes even at their inconvenience. I would '+
      'be certainly maintaining this relationship.',
  },
  {
    clientName: 'Tuoyo M',
    imgURL: images.TImage5,
    position: 5,
    jobDesc: 'Business Analyst',
    id: 4,
    alt: 'Image5',
    feedback:
      'My recruitment experience was seamless, the team is super '+
      'responsive, helpful at all times and it was a pleasure working '+
      'with them. I was first on a staff augmentation contract to ensure'+
      ' a mutual fit between the client and I then got directly hired by '+
      'the health tech company some months later.',
  },
  {
    clientName: 'Beriakuma D',
    imgURL: images.TImage6,
    position: 6,
    jobDesc: 'Product Specialist',
    id: 5,
    alt: 'Image6',
    feedback:
    'Betrworkr was awesome all through my recruitment process. I have '+
    'never met such a  dedicated and professional recruitment team. The '+
    'team was able to efficiently manage expectations, communicate effectively'+
    ' and manage feedbacks. I can not thank the team enough for helping me '+
    'start a new chapter of my professional career.',
  },
];

export const ClientTestimonalData = [
  {
    clientName: 'VFD Group',
    imgURL: images.VFDGroupLogo2,
    feedback:
      'Lorem ipsum dolor sit amet, consectetur adipiscing'+
      ' elit. Tristique egestas lectus mi imperdiet sodales'+
      ' eget sollicitudin. Nulla donec aliquet pellentesque '+
      'ultricies sollicitudin. Et at libero diam amet risus eu'+
      ' a vulputate nunc.',
  },
  {
    clientName: 'Okhi',
    imgURL: images.OkhiLogo2,
    feedback:
    'Lorem ipsum dolor sit amet, consectetur adipiscing'+
    ' elit. Tristique egestas lectus mi imperdiet sodales'+
    ' eget sollicitudin. Nulla donec aliquet pellentesque '+
    'ultricies sollicitudin. Et at libero diam amet risus eu'+
    ' a vulputate nunc.',
  },
  {
    clientName: 'OZE',
    imgURL: images.OZELogo2,
    feedback:
    'Lorem ipsum dolor sit amet, consectetur adipiscing'+
    ' elit. Tristique egestas lectus mi imperdiet sodales'+
    ' eget sollicitudin. Nulla donec aliquet pellentesque '+
    'ultricies sollicitudin. Et at libero diam amet risus eu'+
    ' a vulputate nunc.',
  },
  {
    clientName: 'Shara',
    imgURL: images.SharaLogo2,
    feedback:
    'Lorem ipsum dolor sit amet, consectetur adipiscing'+
    ' elit. Tristique egestas lectus mi imperdiet sodales'+
    ' eget sollicitudin. Nulla donec aliquet pellentesque '+
    'ultricies sollicitudin. Et at libero diam amet risus eu'+
    ' a vulputate nunc.',
  },
];

export const ClientTestimonalData2 = [
  {
    clientName: 'Asaak',
    imgURL: images.AsaakLogo,
    feedback:
      'Lorem ipsum dolor sit amet, consectetur adipiscing'+
      ' elit. Lectus ac ipsum eros, varius massa tellus '+
      'felis, sed. Dignissim placerat mattis ut scelerisque'+
      ' at ut turpis facilisis malesuada. Lectus quisque nunc'+
      ' nec, ultrices quis. A consequat neque dictumst magna '+
      'amet commodo, vestibulum mauris aenean. Egestas vel '+
      'aliquet egestas egestas non nibh ornare. Pharetra non'+
      ' dolor in iaculis mi dolor aliquam nunc.',
  },
  {
    clientName: 'B54',
    imgURL: images.B54Logo,
    feedback:
    'Lorem ipsum dolor sit amet, consectetur adipiscing'+
    ' elit. Lectus ac ipsum eros, varius massa tellus '+
    'felis, sed. Dignissim placerat mattis ut scelerisque'+
    ' at ut turpis facilisis malesuada. Lectus quisque nunc'+
    ' nec, ultrices quis. A consequat neque dictumst magna '+
    'amet commodo, vestibulum mauris aenean. Egestas vel '+
    'aliquet egestas egestas non nibh ornare. Pharetra non'+
    ' dolor in iaculis mi dolor aliquam nunc.',
  },
  {
    clientName: 'Fiter',
    imgURL: images.FiterLogo,
    feedback:
    'Lorem ipsum dolor sit amet, consectetur adipiscing'+
    ' elit. Lectus ac ipsum eros, varius massa tellus '+
    'felis, sed. Dignissim placerat mattis ut scelerisque'+
    ' at ut turpis facilisis malesuada. Lectus quisque nunc'+
    ' nec, ultrices quis. A consequat neque dictumst magna '+
    'amet commodo, vestibulum mauris aenean. Egestas vel '+
    'aliquet egestas egestas non nibh ornare. Pharetra non'+
    ' dolor in iaculis mi dolor aliquam nunc.',
  },
  {
    clientName: 'Founders Factory Africa',
    imgURL: images.FoundersFactoryLogo,
    feedback:
    'Lorem ipsum dolor sit amet, consectetur adipiscing'+
    ' elit. Lectus ac ipsum eros, varius massa tellus '+
    'felis, sed. Dignissim placerat mattis ut scelerisque'+
    ' at ut turpis facilisis malesuada. Lectus quisque nunc'+
    ' nec, ultrices quis. A consequat neque dictumst magna '+
    'amet commodo, vestibulum mauris aenean. Egestas vel '+
    'aliquet egestas egestas non nibh ornare. Pharetra non'+
    ' dolor in iaculis mi dolor aliquam nunc.',
  },
  {
    clientName: 'Market Force',
    imgURL: images.MarketForceLogo,
    feedback:
    'Lorem ipsum dolor sit amet, consectetur adipiscing'+
    ' elit. Lectus ac ipsum eros, varius massa tellus '+
    'felis, sed. Dignissim placerat mattis ut scelerisque'+
    ' at ut turpis facilisis malesuada. Lectus quisque nunc'+
    ' nec, ultrices quis. A consequat neque dictumst magna '+
    'amet commodo, vestibulum mauris aenean. Egestas vel '+
    'aliquet egestas egestas non nibh ornare. Pharetra non'+
    ' dolor in iaculis mi dolor aliquam nunc.',
  },
  {
    clientName: 'VFD Group',
    imgURL: images.VFDGroupLogo,
    feedback:
    'Lorem ipsum dolor sit amet, consectetur adipiscing'+
    ' elit. Lectus ac ipsum eros, varius massa tellus '+
    'felis, sed. Dignissim placerat mattis ut scelerisque'+
    ' at ut turpis facilisis malesuada. Lectus quisque nunc'+
    ' nec, ultrices quis. A consequat neque dictumst magna '+
    'amet commodo, vestibulum mauris aenean. Egestas vel '+
    'aliquet egestas egestas non nibh ornare. Pharetra non'+
    ' dolor in iaculis mi dolor aliquam nunc.',
  },
  {
    clientName: 'Okhi',
    imgURL: images.OkhiLogo,
    feedback:
    'Lorem ipsum dolor sit amet, consectetur adipiscing'+
    ' elit. Lectus ac ipsum eros, varius massa tellus '+
    'felis, sed. Dignissim placerat mattis ut scelerisque'+
    ' at ut turpis facilisis malesuada. Lectus quisque nunc'+
    ' nec, ultrices quis. A consequat neque dictumst magna '+
    'amet commodo, vestibulum mauris aenean. Egestas vel '+
    'aliquet egestas egestas non nibh ornare. Pharetra non'+
    ' dolor in iaculis mi dolor aliquam nunc.',
  },
  {
    clientName: 'OZE',
    imgURL: images.OZELogo,
    feedback:
    'Lorem ipsum dolor sit amet, consectetur adipiscing'+
    ' elit. Lectus ac ipsum eros, varius massa tellus '+
    'felis, sed. Dignissim placerat mattis ut scelerisque'+
    ' at ut turpis facilisis malesuada. Lectus quisque nunc'+
    ' nec, ultrices quis. A consequat neque dictumst magna '+
    'amet commodo, vestibulum mauris aenean. Egestas vel '+
    'aliquet egestas egestas non nibh ornare. Pharetra non'+
    ' dolor in iaculis mi dolor aliquam nunc.',
  },
  {
    clientName: 'Shara',
    imgURL: images.SharaLogo,
    feedback:
    'Lorem ipsum dolor sit amet, consectetur adipiscing'+
    ' elit. Lectus ac ipsum eros, varius massa tellus '+
    'felis, sed. Dignissim placerat mattis ut scelerisque'+
    ' at ut turpis facilisis malesuada. Lectus quisque nunc'+
    ' nec, ultrices quis. A consequat neque dictumst magna '+
    'amet commodo, vestibulum mauris aenean. Egestas vel '+
    'aliquet egestas egestas non nibh ornare. Pharetra non'+
    ' dolor in iaculis mi dolor aliquam nunc.',
  },
];
