import React, {HTMLAttributes} from 'react';
import './row.style.scss';

export interface rowProps extends HTMLAttributes<HTMLDivElement> {
  /** This takes the children elements of the row component */
  children: JSX.Element[] | JSX.Element;
  /** This takes the classname of the row  */
  className: string;
  /** This takes paddingbottom for the row */
  height?: string;
}

/** @return {Funtion} This is the `row component` */
const Row = ({children, className, height}: rowProps) => {
  return (
    <div className={className} data-testid="row-component"
      style={{height: height}}>
      {children}
    </div>
  );
};

export default Row;
