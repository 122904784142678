import React, {HTMLAttributes} from 'react';
import {NavLink} from 'react-router-dom';
import './navlinks.style.scss';

export type navdataType = {
  /** This takes the route each navlink is linked to */
  path: string;
  /** This takes the name of the route */
  name: string;
  imgURL: string;
};
export interface navlinksProps extends HTMLAttributes<HTMLUListElement> {
  /** This hold all the routes */
  navData: navdataType[];
  /** This is the Navlink classname */
  className: string;
  /** This is the click event handler */
  handleClick?: () => void;
}

/** @return {Funtion} This is the `NavLinks` component */
const NavLinks = ({navData, className, handleClick}: navlinksProps) => {
  return (
    <ul className={className}>
      {navData.map((item, index) => (
        <li key={index}>
          <img src={item.imgURL} alt="icon" className="nav-icon" />
          <NavLink
            to={item.path}
            className={({isActive}) => (isActive ? 'link-active' : 'link')}
            onClick={handleClick}
          >
            {item.name}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};

export default NavLinks;
