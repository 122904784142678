import React from 'react';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import FooterContainer from '../../containers/Footer/FooterContainer';
import LoginSection from '../../containers/LoginSection/LoginSection';
import './login.style.scss';

const LogIn = () => {
  return <div className='login-bg'>
    <ScrollToTop />
    <LoginSection />
    <FooterContainer />
  </div>;
};

export default LogIn;
