import React, {HTMLAttributes} from 'react';
import {Link} from 'react-router-dom';
import './button.style.scss';

export interface buttonProps extends HTMLAttributes<HTMLButtonElement> {
  /** Shows  `App Button` className */
  className: string;
  /** This takes the url the `App Button` is to be linked to */
  to?: any;
  /** This gives the `App Button` a title */
  title: string;
  /** This gives the `App Button` a marginLeft property */
  ml?: string;
  /** This gives the `App Button` a marginTop property */
  mt?: string;
  handleClick?: () => void;
}

/** @return {Funtion} This is the `App Button` */
const Button = ({className, to, title, ml, mt, handleClick}: buttonProps) => {
  return (
    <Link to={to} data-testid="button-component">
      <button
        className={className}
        style={{marginLeft: ml, marginTop: mt}}
        onClick={handleClick}
      >
        {title}
      </button>
    </Link>
  );
};

export default Button;
