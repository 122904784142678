import React from 'react';
import './hearfromclient.style.scss';

interface Props{
  imgURL: string;
  clientName: string;
  feedback: string;
}
const ClientTestimonial = (props:Props) => {
  return (
    <div>
      <div className='clientWrapper'>
        <div className='client-details'>
          <div className='img-cont'>
            <img src={props.imgURL} alt='' />
          </div>
          <div className='name-cont'>
            <h3>{props.clientName}</h3>
          </div>
        </div>
        <div className='feedback-cont'>
          <p>{props.feedback}</p>
        </div>
      </div>
    </div>
  );
};

export default ClientTestimonial;
