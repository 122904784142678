import React from 'react';
import CTA from '../../containers/CTA/CTA';
import HeroSection from '../../containers/HeroSection/HeroSection';
import HomeFourWayProcess
  from '../../containers/HomeFourwayProcess/HomeFourWayProcess';
import HomeHR from '../../containers/HomeHR/HomeHR';
import Testimonials from '../../containers/Testimonials/Testimonials';
import './home.style.scss';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
// import ContactSec from '../../containers/ContactSec/ContactSec';
import ClientSection from '../../containers/ClientSection/ClientSection';
import HomeHearFromClient
  from '../../containers/HomeHearFromClient/HomeHearFromClient';
import FooterContainer from '../../containers/Footer/FooterContainer';


const Home = () => {
  return <div className='home-bg'>
    <ScrollToTop />
    <HeroSection/>
    <HomeHR />
    <HomeFourWayProcess />
    <ClientSection />
    <Testimonials />
    <HomeHearFromClient />
    <CTA />
    {/* <ContactSec /> */}
    <FooterContainer />
  </div>;
};

export default Home;
