import React, {HTMLAttributes} from 'react';
import './image.style.scss';
import {motion} from 'framer-motion';

export interface imageProp extends HTMLAttributes<HTMLImageElement> {
  /** This takes the image url */
  imgURL: string;
  /** This takes the alt attribute of the image */
  altText: string;
  /** This takes the className */
  className: string;
};

const Image = ({imgURL, altText, className}: imageProp) => {
  return (
    <motion.div
      whileInView={{
        x: className === 'image-left' ? [0, 40] :
          className === 'image-absolute-right' ? [20, 10] : [-10, -30],
      }}
      transition={{ease: 'easeOut', duration: 2}}
      className={className} data-testid='image-component'
    >
      <img src={imgURL} alt={altText} />
    </motion.div>
  );
};

export default Image;
