import React, {HTMLAttributes} from 'react';
import './linkbutton.style.scss';
import {ImCircleRight} from 'react-icons/im';
import {Link} from 'react-router-dom';

export interface linkButtonProps extends HTMLAttributes<HTMLAnchorElement> {
  /** This displays the text in the `Link Button` */
  title: string;
  /** This takes the URL of the `Link Button` */
  to: any;
  /** This takes the className */
  className: string;
}

/** @return {Funtion} This is the `Link Button` */
const LinkButton = ({title, to, className}: linkButtonProps) => {
  return (
    <Link to={to} className={className} data-testid="link-btn-component">
      <p>{title}</p>
      <span>
        <ImCircleRight />
      </span>
    </Link>
  );
};

export default LinkButton;
