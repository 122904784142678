export const AboutStrings = {
  heading: 'Who we are',
  desc: 'The ability for a business to succeed '+
  'ultimately lies with its people, at Betrworkr, '+
  'we understand that quality talent is at the core'+
  ' of organizational success. \n\nWith proven '+
  'successes in the Tech industry, we eliminate the '+
  'hurdles faced when hiring the right talent; from '+
  'sourcing to vetting through to recruitment, our '+
  'thorough screening processes and interviews help us'+
  ' to make high quality placements for our clients. ',
};
