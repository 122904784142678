import * as React from 'react';
import './svgstyles.scss';
import images from '../images';

const ScreeningSVG = () => (
  <div className='screeningsvg-container'>
    <img src={images.Screening04} alt='Screening04'
      id='boy-box'/>
    <img src={images.Screening01} alt='Screening01'
      id='screening'/>
    <img src={images.Screening02} alt='Screening02'
      id='girl-box1'/>
    <img src={images.Screening03} alt='Screening03'
      id='girl-box2'/>
  </div>
);

export default ScreeningSVG;
