import React from 'react';
import Column from '../../components/Column/Column';
import LogInForm from '../../components/FormElements/LogInForm/LogInForm';
import HeadingText from '../../components/HeadingText/HeadingText';
import Row from '../../components/Row/Row';
import SocialLogin from '../../components/SocialLogin/SocialLogin';
import {images} from '../../resource';
import './loginsection.style.scss';

const LoginSection = () => {
  return (
    <div data-testid='login-component'>
      <Row className='row hero inner-row-reverse' height='800px'>
        <Column className='col-large-three-quarter'
          innerColClass='col-content left'>
          <img src={images.SignInImg} alt="" className='login-section__image' />
        </Column>
        <Column className='col'>
          <HeadingText
            text='Welcome back'
            className='subheading-text text-left'/>
          <LogInForm />
          <SocialLogin text='Or log in with' />
        </Column>
      </Row>
    </div>
  );
};

export default LoginSection;
