import React, {HTMLAttributes} from 'react';
import AvartarHolder from './AvartarHolder';
import './testimonial.scss';


export interface testimonialProp extends HTMLAttributes<HTMLDivElement> {
  imgURL?: string;
  clientName: string;
  jobDesc: string;
  feedback: string;
}

const Testimonial = ({
  imgURL,
  jobDesc,
  clientName,
  feedback}: testimonialProp) => {
  let initial = '';
  const getInitials = (name: string) => {
    let nameArr = [];
    nameArr = name.split(' ');
    initial = `${nameArr[0].charAt(0)}${nameArr[1].charAt(0)}`;
    return initial;
  };
  return (
    <div className="testimonial__wrapper app__lightorangebg"
      data-testid='testimonial-comp'>
      <div className="testimonial__wrapper-client-details">
        {imgURL ? <img src={imgURL} alt='' /> :
          <AvartarHolder initials={getInitials(clientName)} />}
        <div>
          <h3>{clientName}</h3>
          <h6>{jobDesc}</h6>
        </div>
      </div>
      <div className="testimonial__wrapper-client-feedback">
        <p>{feedback}</p>
      </div>
    </div>
  );
};

export default Testimonial;
