import React from 'react';
import Testimonials from '../../containers/Testimonials/Testimonials';
import CTA from '../../containers/CTA/CTA';
import AboutHero from '../../containers/AboutHero/AboutHero';
import './about.style.scss';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
// import ContactSec from '../../containers/ContactSec/ContactSec';
import FooterContainer from '../../containers/Footer/FooterContainer';

const AboutUs = () => {
  return <div className='about-bg'>
    <ScrollToTop />
    <AboutHero />
    <Testimonials />
    <CTA />
    {/* <ContactSec /> */}
    <FooterContainer />
  </div>;
};

export default AboutUs;
