import React from 'react';
import Button from '../../components/button/Button';
import Column from '../../components/Column/Column';
import HeadingText from '../../components/HeadingText/HeadingText';
import ParagraphText from '../../components/ParagraphText/ParagraphText';
import Row from '../../components/Row/Row';
import {images} from '../../resource';
import './herosection.style.scss';
import {motion} from 'framer-motion';
import Image from '../../components/Image/Image';
import {HomeStrings} from '../../resource/strings/HomeStrings';

const HeroSection = () => {
  return (
    <Row className='row bg-lightblue hero' height='100vh'>
      <Column className='col' innerColClass='col-hero'>
        <HeadingText className='hero-heading-text'
          text={HomeStrings.hero.heroHeading} />
        <ParagraphText className='hero-paragraph-text'
          text={HomeStrings.hero.heroDesc} />
        <Button className='primary-large-btn'
          to={HomeStrings.hero.herobtn.URL}
          title={HomeStrings.hero.herobtn.text} />
        <div className="strokeCircle">
          <img src={images.StrokeCircle} alt="strokeCircle" />
        </div>
      </Column>
      <Column className='col' innerColClass='inner-col right'>
        <motion.div
          whileInView={{opacity: [0, 1], scale: [0, 1]}}
          transition={{duration: 0.9, delay: 1}}
          className="star"
        >
          <img src={images.Star} alt="star" />
        </motion.div>
        <Image imgURL={images.HeroImg} altText='heroImage'
          className='image-absolute-right' />
      </Column>
    </Row>
  );
};

export default HeroSection;
