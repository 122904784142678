import React, {HTMLAttributes} from 'react';
import {images} from '../../resource';
import ParagraphText from '../ParagraphText/ParagraphText';
import './sociallogin.style.scss';
import {Link} from 'react-router-dom';

export interface socialLoginProp extends HTMLAttributes<HTMLDivElement> {
  text: string;
}

const SocialLogin = ({text}: socialLoginProp) => {
  return (
    <div className="login__social" data-testid="social-login-component">
      <ParagraphText text={text} className="paragraph-text text-center" />
      <ul className="login__col-social">
        <li>
          <Link to="/">
            <img src={images.Google} alt="Google" />
          </Link>
        </li>
        <li>
          <div></div>
        </li>
        <li>
          <Link to="/">
            <img src={images.Facebook} alt="Google" />
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default SocialLogin;
