import React from 'react';
import Column from '../../components/Column/Column';
import SignUpForm from '../../components/FormElements/SignUpForm/SignUpForm';
import HeadingText from '../../components/HeadingText/HeadingText';
import Row from '../../components/Row/Row';
import SocialLogin from '../../components/SocialLogin/SocialLogin';
import {images} from '../../resource';
import './signupsection.style.scss';

const SignUpSection = () => {
  return (
    <div data-testid='signup-component'>
      <Row className='row hero inner-row-reverse' height='800px'>
        <Column className='col-large-three-quarter'
          innerColClass='col-content left'>
          <img src={images.SignUpImg} alt=""
            className='signup-section__image' />
        </Column>
        <Column className='col'>
          <HeadingText
            text='Get started with us here'
            className='subheading-text text-left'/>
          <SignUpForm />
          <SocialLogin text='Or sign up with' />
        </Column>
      </Row>
    </div>
  );
};

export default SignUpSection;
