import React from 'react';
import Column from '../../components/Column/Column';
import HeadingText from '../../components/HeadingText/HeadingText';
import Row from '../../components/Row/Row';
import ClientCarousel from './ClientCarousel';
import './hearfromclient.style.scss';

const HomeHearFromClient = () => {
  return (
    <div className='hear-from-client-container'>
      <Row className='row'>
        <Column className='col-large-full'>
          <HeadingText text='Hear from Clients'
            className='subheading-text heading-white text-center'
          />
        </Column>
      </Row>
      <Row className='inner-row-justify-center'>
        <ClientCarousel />
      </Row>
    </div>
  );
};

export default HomeHearFromClient;
