import React, {HTMLAttributes} from 'react';
import './footerlinks.style.scss';
import {Link} from 'react-router-dom';

export type footerdataType = {
  /** This takes the route each footer navlink is linked to */
  to: string;
  /** This takes the name of the route */
  name: string;
};
export interface footerlinksProps extends HTMLAttributes<HTMLUListElement> {
  /** This hold all the routes */
  footerData: footerdataType[];
  /** This hold footer link heading */
  heading: string;
  /** This is the click event handler */
  handleClick?: () => void;
}
const FooterLinks = ({heading, footerData, handleClick}: footerlinksProps) => {
  return (
    <div className='footer__navlinks' data-testid='footer-links-component'>
      <h3>{heading}</h3>
      <ul>
        {footerData.map((item, index) => (
          <li key={`company${index}`}>
            <Link to={item.to} className="footer_col-links"
              onClick={handleClick}>
              {item.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FooterLinks;
