import React from 'react';
import Column from '../../components/Column/Column';
import HeadingText from '../../components/HeadingText/HeadingText';
import Image from '../../components/Image/Image';
import ParagraphText from '../../components/ParagraphText/ParagraphText';
import Row from '../../components/Row/Row';
import {images} from '../../resource';
import {AboutStrings} from '../../resource/strings/AboutString';

const AboutHero = () => {
  return (
    <div data-testid='about-component'>
      <Row className='row hero inner-row-reverse'>
        <Column className='col' innerColClass='col-content left'>
          <Image imgURL={images.AboutImg} altText='AboutImage'
            className='image-left' />
        </Column>
        <Column className='col' innerColClass='col-content right'>
          <HeadingText
            text={AboutStrings.heading}
            className='subheading-text app__flex-end'/>
          <ParagraphText className='paragraph-text text-right'
            text={AboutStrings.desc} />
        </Column>
      </Row>
    </div>
  );
};

export default AboutHero;
