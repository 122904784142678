import * as React from 'react';
import './svgstyles.scss';
import images from '../images';

const RecruitingSVG = () => (
  <div className='recruiting-container'>
    <img src={images.Recruiting01} alt='Recruiting1' id='screening01' />
    <img src={images.Recruiting02} alt='Recruiting2' id='screening02' />
    <img src={images.Recruiting03} alt='Recruiting3' id='gear2' />
    <img src={images.Recruiting04} alt='Recruiting4' id='gear1' />
    <img src={images.Recruiting05} alt='Recruiting5' id='gear3' />
  </div>
);

export default RecruitingSVG;
