export const ClientsData = {
  row1: [
    {
      altText: 'VFDGroupLogo',
      imgURL: require('../assets/vfdgroup-logo.png'),
    },
    {
      altText: 'SharaLogo',
      imgURL: require('../assets/shara-logo.png'),
    },
    {
      altText: 'MarketForceLogo',
      imgURL: require('../assets/marketforce-logo.png'),
    },
    {
      altText: 'OkhiLogo',
      imgURL: require('../assets/okhi-logo.png'),
    },
    {
      altText: 'B54Logo',
      imgURL: require('../assets/B54logo.png'),
    },
    {
      altText: 'VentraeLogo',
      imgURL: require('../assets/ventrae-logo.png'),
    },
  ],
  row2: [
    {
      altText: 'FiterLogo',
      imgURL: require('../assets/fiter-logo.png'),
    },
    {
      altText: 'FoundersFactoryLogo',
      imgURL: require('../assets/foundersfactoryafrica.png'),
    },
    {
      altText: 'AsaakLogo',
      imgURL: require('../assets/asaaklogo.png'),
    },
    {
      altText: 'TripploLogo',
      imgURL: require('../assets/tripplologo.png'),
    },
    {
      altText: 'OZELogo',
      imgURL: require('../assets/ozelogo.png'),
    },
    {
      altText: 'Nailed2goLogo',
      imgURL: require('../assets/nailed2go2.png'),
    },
  ],
};

export const ClientsData2 = {
  row1: [
    {
      altText: 'VFDGroupLogo',
      imgURL: require('../assets/vfdglogo2.png'),
    },
    {
      altText: 'SharaLogo',
      imgURL: require('../assets/sharalogo2.png'),
    },
    {
      altText: 'MarketForceLogo',
      imgURL: require('../assets/marketforcelogo2.png'),
    },
    {
      altText: 'OkhiLogo',
      imgURL: require('../assets/okhilogo2.png'),
    },
    {
      altText: 'B54Logo',
      imgURL: require('../assets/b54logo2.png'),
    },
    {
      altText: 'VentraeLogo',
      imgURL: require('../assets/ventraelogo2.png'),
    },
  ],
  row2: [
    {
      altText: 'FiterLogo',
      imgURL: require('../assets/fiterlogo2.png'),
    },
    {
      altText: 'FoundersFactoryLogo',
      imgURL: require('../assets/ffalogo2.png'),
    },
    {
      altText: 'AsaakLogo',
      imgURL: require('../assets/asaaklogo2.png'),
    },
    {
      altText: 'TripploLogo',
      imgURL: require('../assets/tripplologo2.png'),
    },
    {
      altText: 'OZELogo',
      imgURL: require('../assets/ozelogo2.png'),
    },
    {
      altText: 'Nailed2goLogo',
      imgURL: require('../assets/nailed2go.png'),
    },
  ],
};

export const ClientsDataMob = {
  row1: [
    {
      altText: 'VFDGroupLogo',
      imgURL: require('../assets/vfdgroup-logo.png'),
    },
    {
      altText: 'SharaLogo',
      imgURL: require('../assets/shara-logo.png'),
    },
    {
      altText: 'MarketForceLogo',
      imgURL: require('../assets/marketforce-logo.png'),
    },
    {
      altText: 'OkhiLogo',
      imgURL: require('../assets/okhi-logo.png'),
    },
  ],
  row2: [
    {
      altText: 'FiterLogo',
      imgURL: require('../assets/fiter-logo.png'),
    },
    {
      altText: 'FoundersFactoryLogo',
      imgURL: require('../assets/foundersfactoryafrica.png'),
    },
    {
      altText: 'B54Logo',
      imgURL: require('../assets/B54logo.png'),
    },
    {
      altText: 'VentraeLogo',
      imgURL: require('../assets/ventrae-logo.png'),
    },
  ],
  row3: [
    {
      altText: 'AsaakLogo',
      imgURL: require('../assets/asaaklogo.png'),
    },
    {
      altText: 'TripploLogo',
      imgURL: require('../assets/tripplologo.png'),
    },
    {
      altText: 'OZELogo',
      imgURL: require('../assets/ozelogo.png'),
    },
  ],
};

export const ClientsDataMob2 = {
  row1: [
    {
      altText: 'VFDGroupLogo',
      imgURL: require('../assets/vfdglogo2.png'),
    },
    {
      altText: 'SharaLogo',
      imgURL: require('../assets/sharalogo2.png'),
    },
    {
      altText: 'MarketForceLogo',
      imgURL: require('../assets/marketforcelogo2.png'),
    },
    {
      altText: 'OkhiLogo',
      imgURL: require('../assets/okhilogo2.png'),
    },
  ],
  row2: [
    {
      altText: 'FiterLogo',
      imgURL: require('../assets/fiterlogo2.png'),
    },
    {
      altText: 'FoundersFactoryLogo',
      imgURL: require('../assets/ffalogo2.png'),
    },
    {
      altText: 'B54Logo',
      imgURL: require('../assets/b54logo2.png'),
    },
    {
      altText: 'VentraeLogo',
      imgURL: require('../assets/ventraelogo2.png'),
    },
  ],
  row3: [
    {
      altText: 'AsaakLogo',
      imgURL: require('../assets/asaaklogo2.png'),
    },
    {
      altText: 'TripploLogo',
      imgURL: require('../assets/tripplologo2.png'),
    },
    {
      altText: 'OZELogo',
      imgURL: require('../assets/ozelogo2.png'),
    },
    {
      altText: 'Nailed2goLogo',
      imgURL: require('../assets/nailed2go.png'),
    },
  ],
};
