import React, {HTMLAttributes} from 'react';
import './headingtext.style.scss';

export interface headingTextProps extends HTMLAttributes<HTMLHeadingElement> {
  /** This takes the text */
  text: string;
  /** This takes the className */
  className: string;
}

/** @return {Funtion} This is the `Heading text component` */
const HeadingText = ({text, className}: headingTextProps) => {
  return (
    <div className={className} data-testid="heading-text-test">
      { className === 'hero-heading-text' ? <h1>{text}</h1> : <h2>{text}</h2>}
    </div>
  );
};

export default HeadingText;
