import React from 'react';
import TextInput from '../TextInput';
import '../../button/button.style.scss';

const LogInForm = () => {
  return (
    <form data-testid='login-form-component'>
      <div className='contact__inner-row'>
        <TextInput labelName='Username'
          inputType='text'
          name='username' />
      </div>
      <div className='contact__inner-row'>
        <TextInput labelName='Password'
          inputType='password'
          name='password' />
      </div>
      <div className='contact__inner-row'>
        <input
          type="submit"
          value="Log In"
          className="gradient-large-btn"
        />
      </div>
    </form>
  );
};

export default LogInForm;
