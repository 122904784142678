import React, {HTMLAttributes} from 'react';
import './footercontact.style.scss';

export interface footerContactProps extends HTMLAttributes<HTMLUListElement> {
  /** holds contact paragraph */
  contactDesc: string;
  /** This hold footer contact heading */
  heading: string;
}

const FooterContact = ({heading, contactDesc}: footerContactProps) => {
  return (
    <div className='footer__contact' data-testid='footer-contact-component'>
      <h3>{heading}</h3>
      <a href={`mailto:${contactDesc}`}><p>{contactDesc}</p></a>
    </div>
  );
};

export default FooterContact;
