import React, {HTMLAttributes} from 'react';
import {FiMenu} from 'react-icons/fi';
import {HiX} from 'react-icons/hi';
import {FooterStrings} from '../../resource/strings/FooterStrings';
import Button from '../button/Button';
import FooterContact from '../FooterContact/FooterContact';
import FooterLinks from '../FooterLinks/FooterLinks';
import NavLinks, {navdataType} from '../NavLinks/NavLinks';
import SocialIcons from '../SocialIcons/SocialIcons';
import './mobilemenu.style.scss';

export interface mobileMenuProps extends HTMLAttributes<HTMLDivElement> {
  /** This takes the navlinks */
  navData: navdataType[];
  /** This takes the navlinks className */
  className: string;
  /** This takes the toggleMenu boolean */
  toggleMenu: boolean;
  /** This takes the hamburger menu click function */
  menuClick: () => void;
  /** This takes the close menu click function */
  closeMenuClick: () => void;
  /** This takes the navlinks and button click function */
  handleClick: () => void;
}
/** @return {Funtion} This is the `Mobilemenu component` */
const MobileMenu = ({
  className,
  navData,
  toggleMenu,
  menuClick,
  closeMenuClick,
  handleClick,
}: mobileMenuProps) => {
  return (
    <div className="app__navbar-menu">
      <FiMenu onClick={menuClick} data-testid="hamburger-menu" />

      {toggleMenu && (
        <div className="overlay slide-bottom" data-testid="overlay-screen">
          <HiX onClick={closeMenuClick} data-testid="close-menu" />
          <div className="signup">
            <Button
              to="/log-in"
              title="Log In"
              className="secondary-medium-btn"
              handleClick={handleClick}
            />
            <Button
              to="/sign-up"
              title="Sign Up"
              className="primary-medium-btn"
              ml="20px"
              handleClick={handleClick}
            />
          </div>
          <NavLinks
            navData={navData}
            className={className}
            handleClick={handleClick}
          />
          <div className="mobilemenu__row">
            <div className="mobilemenu__col">
              <FooterLinks heading={FooterStrings.company.heading}
                footerData={FooterStrings.company.links}
                handleClick={handleClick} />
            </div>
            <div className="mobilemenu__col">
              <FooterLinks heading={FooterStrings.legal.heading}
                footerData={FooterStrings.legal.links}
                handleClick={handleClick} />
            </div>
            <div className="mobilemenu__col2">
              <FooterContact heading={FooterStrings.contact.heading}
                contactDesc={FooterStrings.contact.desc} />
            </div>
            <div className="mobilemenu__col2">
              <SocialIcons />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileMenu;
